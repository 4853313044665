// components/Alert.js
import React from 'react';
const Alert = ({ message }) => {
  return (
    <div style={{height: '50px'}}>
    <div className="alert alert-warning" role="alert">
      {message}
    </div>
    </div>
  );
};

export default Alert;
