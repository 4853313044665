import './App.css';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import About from './components/About';
import Alert from './components/Alert';
import ColorPalette from './components/ColorPalette';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  const [mode, setMode] = useState('light'); // Dark & Light Mode
  const [alert, setAlert] = useState(null);   // Alert state for messages

  // Function to show alert messages
  const showAlert = (message) => {
    setAlert(message);
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  // Function to handle color changes
  const handleColorChange = (color) => {
    document.documentElement.style.setProperty('--primary-color', color); // Set color in CSS variable
    showAlert("Theme color changed!"); // Show alert for color change
  };

  // Light and dark mode toggle function
  const toggleMode = () => {
    if (mode === 'light') {
      setMode('dark');
      document.body.style.backgroundColor = "black";
      showAlert("Dark mode has been enabled!");
      document.title = "textutils - Dark Mode";
    } else {
      setMode('light');
      document.body.style.backgroundColor = "white";
      showAlert("Light mode has been enabled!");
      document.title = "textutils - Light Mode";
    }
  };

  return (
    <>
      <Router>
        <Navbar title="Textutils" aboutText="About Us" mode={mode} toggleMode={toggleMode} />
        {<ColorPalette onColorSelect={handleColorChange} />}
        {alert && <Alert message={alert} />} {/* Render Alert if there's a message */}
        
        <div className="container my-3">
          <Routes>
            <Route exact path="/about" element={<About mode={mode} />} />
            <Route exact path="/" element={<TextForm showAlert={showAlert} heading="Enter the text to analyze" mode={mode} />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
