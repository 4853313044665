import React, {useState} from 'react';
export default function TextForm(props) {

  const handleUpClick = ()=>{
    let newText = text.toUpperCase();
    setText (newText);
    props.showAlert('Text converted to uppercase!');
  }

  const handleLoClick = ()=>{
    let newText = text.toLowerCase();
    setText (newText);
    props.showAlert('Text converted to Lowercase!');
  }


  const handleOnChange = (Ievent)=>{
    setText(Ievent.target.value)
  }

  const colorChange = ()=>{
    setcolor ('red');
    props.showAlert('Color Changed');
  }
  const [text, setText] = useState('');
  const [textColor, setcolor] = useState('green');
  return (
    <>
    <div className="container mt-4" style={{color: props.mode === 'dark' ? 'white' : 'black'}}>
        <div className="row">
            <h3>{props.heading}</h3>
           <div className="mb-3">
            <label htmlFor="myBox" className="form-label"></label>
             <textarea className="form-control" id="myBox" rows="8" value={text} onChange={handleOnChange} style={{
                            backgroundColor: props.mode === 'light' ? 'white' : 'black',
                            color: props.mode === 'light' ? 'black' : 'white'
        }}></textarea>
           </div>
           <div className="row">
           <button className="btn btn-primary w-25" onClick={handleUpClick} disabled={!text.trim()}>Convert To Uppercase</button>
           <button className="btn btn-primary w-25 ms-2" onClick={handleLoClick} disabled={!text.trim()}>Convert To Lowercase</button>
           <button className="btn btn-primary w-25 ms-2" onClick={colorChange} disabled={!text.trim()}>Change Text Color</button>
           </div>
           
        </div>
        <div className="container">
      <h1>Length of text</h1>
      <p>{text.trim().split(/\s+/).filter(Boolean).length} Words and {text.length} characters</p>
      <h2>Preview</h2>
      <p style={{ color: textColor }}>{text.length === 0 ? 'Type Something' : text}</p>
    </div>
    </div>

   
    </>
  )
}
