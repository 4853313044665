import React from "react";

const ColorPalette = ({ onColorSelect }) => {
    const colors = ["#4CAF50", "#2196F3", "#FF5722"]; // Green, Blue, Orange

    return (
        <div style={{ display: "flex", gap: "10px", padding: "10px" }}>
            {colors.map((color) => (
                <button
                    key={color}
                    onClick={() => onColorSelect(color)}
                    style={{
                        backgroundColor: color,
                        border: "2px solid white",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                        borderRadius: "50%",
                    }}
                />
            ))}
        </div>
    );
};

export default ColorPalette;
